import { ArrowRightIcon } from "lucide-react";

import { NavigationMenuLink } from "@/components/ui/navigation-menu";
import { cn } from "@/lib/utils";

export const ListItem = ({
	ref,
	className,
	href,
	title,
	isIndex,
	children,
}: React.ComponentProps<"a"> & { isIndex?: boolean; title?: string }) => {
	if (isIndex) {
		return (
			<li key={href}>
				<NavigationMenuLink asChild>
					{
						<a
							ref={ref}
							href={href}
							className={cn(
								"flex items-center space-x-3 whitespace-nowrap p-2 font-semibold text-gray-900 hover:text-primary lg:hover:bg-gray-100",
								className
							)}
						>
							{children ? children : title === "view-all" ? "View all" : title}
							<ArrowRightIcon size={16} />
						</a>
					}
				</NavigationMenuLink>
			</li>
		);
	}

	return (
		<li>
			<NavigationMenuLink asChild>
				{
					<a
						ref={ref}
						href={href}
						className={cn(
							"block whitespace-nowrap rounded p-1 text-gray-900 hover:text-primary lg:p-2 lg:hover:bg-gray-100",
							className
						)}
					>
						{children ? children : title}
					</a>
				}
			</NavigationMenuLink>
		</li>
	);
};

ListItem.displayName = "ListItem";
