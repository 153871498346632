"use client";

import Link from "next/link";
import { usePathname } from "next/navigation";
import { useMemo } from "react";

import { buttonVariants } from "@/components/ui/button";
import { useUI } from "@/components/ui/context";
import { toast } from "@/components/ui/use-toast";
import { companyMembersLink, companyUsageLink, memberLinks } from "@/lib/data/navigation";
import { useEnterprise, useIsAdmin } from "@/lib/hooks/use-enterprise";
import { cn } from "@/lib/utils";
import { User } from "@/types";

const LogoutLink = () => {
	const { closeMenu } = useUI();

	const handleLogout = async () => {
		try {
			closeMenu();

			await fetch("/api/logout");

			// force full-page reload
			window.location.href = "/";
		} catch (error) {
			toast({
				title: "Logout Failed. Please try again.",
				variant: "error",
			});
		}
	};

	return (
		<div
			className={cn(
				buttonVariants({ variant: "text" }),
				"w-full cursor-pointer justify-start px-1.5 text-gray-700 hover:bg-gray-200 sm:hover:text-examine-purple-500"
			)}
			onClick={handleLogout}
		>
			Logout
		</div>
	);
};

interface MembersMenuProps {
	user: User;
}

export default function MembersMenu({ user }: MembersMenuProps) {
	const pathname = usePathname();
	const { isEnterpriseUser } = useEnterprise();
	const { isAdmin } = useIsAdmin();
	const { closeMenu } = useUI();

	const links = useMemo(() => {
		let links = memberLinks;

		if (isEnterpriseUser && !user?.affiliate) {
			links = links.filter((link) => link.href !== "/members/myreferrals/");
		}

		if (isAdmin) {
			links = [...links, companyMembersLink, companyUsageLink];
		}

		return links;
	}, [isEnterpriseUser, isAdmin, user]);

	return (
		<>
			{links.map((link) => {
				return (
					<Link
						href={link.href}
						key={link.href}
						className={cn(
							buttonVariants({ variant: "text" }),
							"w-full justify-start whitespace-nowrap px-1.5 text-gray-700 hover:bg-gray-200 sm:hover:text-examine-purple-500",
							link.href === pathname && "bg-gray-200 text-examine-purple-500"
						)}
						onClick={() => closeMenu()}
					>
						{link.name}
					</Link>
				);
			})}
			<LogoutLink />
		</>
	);
}
