"use client";

import { getCookie } from "cookies-next";
import { ChevronDown, XCircle } from "lucide-react";
import Image from "next/image";
import Link from "next/link";
import { useSearchParams } from "next/navigation";
import * as React from "react";

import { AccessCheck } from "@/components/access-check";
import { ExaminePlusLink } from "@/components/cta";
import EndsTonight from "@/components/ends-tonight";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import Menu from "@/components/icons/menu";
import { LoginLink } from "@/components/login-link";
import MembersMenu from "@/components/members-menu";
import SaleWrapper from "@/components/sale-wrapper";
import SearchInput from "@/components/search/search-input";
import { Badge } from "@/components/ui/badge";
import { Button, buttonVariants } from "@/components/ui/button";
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from "@/components/ui/collapsible";
import { MenuUI } from "@/components/ui/context";
import { useUI } from "@/components/ui/context";
import Logo from "@/components/ui/logo";
import { NavigationMenu, NavigationMenuList } from "@/components/ui/navigation-menu";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Sheet, SheetClose, SheetContent, SheetHeader, SheetTrigger } from "@/components/ui/sheet";
import { navLinksMobile } from "@/lib/data/navigation";
import { cn } from "@/lib/utils";
import { Feature, SubscriptionInfo, User } from "@/types/index";

import { AboutMenu, ExaminePlusMenu, UserMenu } from "./menus";

interface MobileNavigationProps {
	user: User;
	subscription: SubscriptionInfo;
	features: Feature[];
}

export default function MobileNavigation({ user, subscription, features }: MobileNavigationProps) {
	const searchParams = useSearchParams();
	const { activeMenu, displayMenu, openMenu, closeMenu } = useUI();

	const token = getCookie("token");
	const isPaidAd = searchParams.has("paidad") ? searchParams.get("paidad") : null;

	const handleOpenChange = (open: boolean) => {
		if (open) {
			openMenu();
		} else {
			closeMenu();
		}
	};

	const getUserStatus = () => {
		if (!user) return "logged-out";
		return subscription ? "paid" : "free";
	};

	const canAccessLink = (link: (typeof navLinksMobile)[0]) => {
		if (!link.access) return true;

		const userStatus = getUserStatus();
		return link.access.includes(userStatus);
	};

	return (
		<Sheet open={displayMenu} onOpenChange={handleOpenChange}>
			<SheetTrigger asChild>
				<Button
					className="h-[30px] w-8 rounded bg-examine-purple-medium p-1.5 lg:hidden"
					aria-label="Open menu"
					aria-expanded="false"
					aria-controls="menu"
				>
					<Menu className="w-full text-white" />
				</Button>
			</SheetTrigger>

			<SheetContent side="left" className="w-full px-7 py-4">
				{/* Header */}
				<SheetHeader className="flex flex-row items-center justify-between space-y-0 border-none bg-white pb-0">
					{/* logo */}
					<div className="flex items-center space-x-2">
						<SheetClose asChild>
							<Link href="/">
								<Logo className="h-4 w-18 text-examine-purple-500" />
							</Link>
						</SheetClose>
						<SaleWrapper
							cta={
								<FreeTrialWrapper>
									<ExaminePlusLink
										onClick={() => {
											document.body.classList.remove("overflow-hidden");
										}}
										trackDetails={{
											trackFor: "trial",
											location: "mobile hamburger menu above search",
											label: "Examine logo <<Try Examine+ for free>>",
										}}
										className="view-page"
									>
										<Badge variant="cyan" size="sm" className="ml-1.5 space-x-1 text-xs">
											Try Examine+ for free
										</Badge>
									</ExaminePlusLink>
								</FreeTrialWrapper>
							}
						>
							<ExaminePlusLink
								onClick={() => {
									document.body.classList.remove("overflow-hidden");
								}}
								trackDetails={{
									trackFor: "sale",
									location: "mobile hamburger menu above search",
									label: "Examine logo <<ON SALE>>",
								}}
							>
								<Badge variant="cyan" className="ml-1.5 space-x-1">
									<span>Examine+</span>
									<span>
										<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
											ON SALE
										</EndsTonight>
									</span>
								</Badge>
							</ExaminePlusLink>
						</SaleWrapper>
					</div>
					<SheetClose className="-mr-2" asChild>
						<Button className="p-1" variant="text">
							<XCircle className="h-6 w-6" color="#000" />
						</Button>
					</SheetClose>
				</SheetHeader>

				<ScrollArea className="h-[calc(100vh-6rem)]">
					<div className="space-y-4">
						{activeMenu === "profile-menu" ? (
							<div className="border-t border-gray-300 pb-30 pt-4">
								<div className="relative mt-3 max-w-md overflow-hidden -tracking-2 transition-[height] duration-300 md:px-7">
									<p className="whitespace-nowrap font-bold text-gray-900">{user?.full_name}</p>
									{subscription?.is_active && (
										<p className="text-sm text-gray-600">{subscription?.name}</p>
									)}
								</div>

								<div className="relative mt-3 max-w-md overflow-hidden transition-[height] duration-300 md:px-7">
									<nav className="space-y-2 leading-9 -tracking-2">
										<MembersMenu user={user} />
									</nav>
								</div>
							</div>
						) : (
							<React.Fragment>
								<div className="z-20 mx-auto flex w-full max-w-md items-center gap-4">
									{token ? (
										<React.Fragment>
											<SheetClose asChild>
												<Link
													href="/members/dashboard/"
													className={cn(
														buttonVariants(),
														"block flex-1 rounded-md border text-center"
													)}
												>
													Dashboard
												</Link>
											</SheetClose>
											<SheetClose asChild>
												<Link
													href="/members/profile/"
													className={cn(
														buttonVariants(),
														"block flex-1 rounded-md border text-center"
													)}
												>
													My Profile
												</Link>
											</SheetClose>
										</React.Fragment>
									) : (
										<React.Fragment>
											<LoginLink
												className={cn(
													buttonVariants(),
													"block flex-1 rounded-md border text-center"
												)}
											>
												Log in
											</LoginLink>
											<SheetClose asChild>
												<Link
													href="/signup/"
													className={cn(
														buttonVariants(),
														"block flex-1 rounded-md border text-center"
													)}
												>
													Sign up
												</Link>
											</SheetClose>
										</React.Fragment>
									)}
								</div>
								<SaleWrapper>
									<SheetClose asChild>
										<ExaminePlusLink
											trackDetails={{
												trackFor: "sale",
												location: "mobile hamburger menu above search",
												label: "Examine logo <<ON SALE>>",
											}}
											className="mt-5 flex items-center justify-center gap-2 bg-examine-purple-100 text-gray-900"
										>
											<div className="relative h-5 w-20 md:h-7 md:w-32">
												<Image fill={true} src="/sales/logo.svg" alt="Examine.com" />
											</div>
											<Badge variant="cyan" className="mt-2 space-x-1">
												<EndsTonight dayBeforeCta="SALE ENDS TOMORROW" lastDay="LAST DAY OF SALE">
													ON SALE
												</EndsTonight>
											</Badge>
										</ExaminePlusLink>
									</SheetClose>
								</SaleWrapper>
								<div>
									<SearchInput home />
								</div>
								<AccessCheck
									feature={["study_summaries", "examine_database", "supplement_guides"]}
									featureCheck="any"
									cta={
										<div className="pt-0 text-center text-sm text-gray-500">
											<SheetClose asChild>
												<Link
													href="/examine-ai/"
													className={cn(
														"mx-auto items-center justify-center font-medium",
														buttonVariants({ variant: "premium" })
													)}
												>
													Search with ExamineAI
												</Link>
											</SheetClose>
										</div>
									}
								/>
								<MenuUI />
								<Collapsible defaultOpen className="border-t border-gray-300 pb-30 pt-4">
									<CollapsibleTrigger className="group flex w-full items-center justify-between">
										<span className="font-lora text-22 font-medium text-primary">Examine</span>
										<span className="ml-auto grid h-7 w-7 place-content-center rounded-full bg-examine-purple-100">
											<ChevronDown className="h-5 w-5 transition-transform duration-500 group-data-[state=open]:rotate-180" />
										</span>
									</CollapsibleTrigger>

									<CollapsibleContent className="mt-3 max-w-md overflow-hidden md:px-7">
										<NavigationMenu className="block max-w-none" orientation="vertical">
											<NavigationMenuList className="leading-9 -tracking-2">
												{navLinksMobile.map((link) => {
													if (isPaidAd && [0, 1, 2, 5, 6, 7].includes(link.id)) return null;
													if (!canAccessLink(link)) return null;

													if (link.href === "/plus-info/") {
														return (
															<ExaminePlusMenu key={link.id + link.href} features={features} />
														);
													}

													if (link.href === "/about/") {
														return <AboutMenu key={link.id + link.href} />;
													}

													if (link.href === "/members/dashboard/") {
														return (
															<UserMenu key={link.id + link.href} {...{ user, subscription }} />
														);
													}

													return (
														<SheetClose asChild key={link.id + link.href}>
															<Link href={link.href} className="block">
																<span className="flex gap-x-1.5">
																	{link.name}
																	<span>{link.icon}</span>
																</span>
															</Link>
														</SheetClose>
													);
												})}
											</NavigationMenuList>
										</NavigationMenu>
									</CollapsibleContent>
								</Collapsible>
							</React.Fragment>
						)}
					</div>
				</ScrollArea>
			</SheetContent>
		</Sheet>
	);
}
