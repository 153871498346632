import * as NavigationMenuPrimitive from "@radix-ui/react-navigation-menu";
import { cva } from "class-variance-authority";
import { ChevronDown } from "lucide-react";
import * as React from "react";

import { cn } from "@/lib/utils";

const NavigationMenu = ({
	className,
	children,
	viewport = false,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Root> & {
	viewport?: boolean;
}) => (
	<NavigationMenuPrimitive.Root
		className={cn("relative z-10 flex max-w-max flex-1 items-center justify-center", className)}
		{...props}
	>
		{children}
		{viewport && <NavigationMenuViewport />}
	</NavigationMenuPrimitive.Root>
);
NavigationMenu.displayName = NavigationMenuPrimitive.Root.displayName;

const NavigationMenuList = ({
	className,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.List>) => (
	<NavigationMenuPrimitive.List
		className={cn(
			"flex flex-1 list-none items-center justify-center",
			"data-[orientation=vertical]:flex-col data-[orientation=vertical]:items-start data-[orientation=horizontal]:space-x-1 data-[orientation=vertical]:space-y-2",
			className
		)}
		{...props}
	/>
);
NavigationMenuList.displayName = NavigationMenuPrimitive.List.displayName;

const NavigationMenuItem = ({
	className,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Item>) => (
	<NavigationMenuPrimitive.Item
		className={cn(
			"relative [&:last-of-type>div]:left-auto [&:last-of-type>div]:right-0 [&:last-of-type>div]:translate-x-0",
			className
		)}
		{...props}
	/>
);
NavigationMenuItem.displayName = "NavigationMenuItem";

const navigationMenuTriggerStyle = cva(
	"group inline-flex w-max items-center justify-center rounded-md bg-background px-4 py-2 transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground focus:outline-none disabled:pointer-events-none disabled:opacity-50 data-[active]:bg-accent/50 data-[state=open]:bg-accent/50 hover:text-stroke whitespace-nowrap decoration-2 hover:text-primary hover:underline xl:px-3"
);

const NavigationMenuTrigger = ({
	className,
	children,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Trigger>) => (
	<NavigationMenuPrimitive.Trigger
		className={cn(navigationMenuTriggerStyle(), className)}
		{...props}
	>
		{children}{" "}
		<ChevronDown
			className="relative top-px ml-1 size-4 transition duration-75 group-data-[state=open]:rotate-180"
			aria-hidden="true"
		/>
	</NavigationMenuPrimitive.Trigger>
);
NavigationMenuTrigger.displayName = NavigationMenuPrimitive.Trigger.displayName;

const NavigationMenuContent = ({
	className,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Content>) => (
	<NavigationMenuPrimitive.Content
		className={cn(
			"left-1/2 top-full w-full -translate-x-1/2 rounded-md border bg-white shadow-lg",
			"data-[motion^=from-]:animate-in data-[motion^=to-]:animate-out data-[motion^=from-]:fade-in data-[motion^=to-]:fade-out data-[motion=from-end]:slide-in-from-right-52 data-[motion=from-start]:slide-in-from-left-52 data-[motion=to-end]:slide-out-to-right-52 data-[motion=to-start]:slide-out-to-left-52 md:absolute md:w-auto",
			className
		)}
		{...props}
	/>
);
NavigationMenuContent.displayName = NavigationMenuPrimitive.Content.displayName;

const NavigationMenuLink = NavigationMenuPrimitive.Link;

const NavigationMenuViewport = ({
	className,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Viewport>) => (
	<div className="viewport absolute top-full flex justify-center">
		<NavigationMenuPrimitive.Viewport
			className={cn(
				"origin-top-center bg-popover text-popover-foreground relative mt-1.5 h-[var(--radix-navigation-menu-viewport-height)] w-full overflow-hidden rounded-md shadow-lg data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-90 md:w-[var(--radix-navigation-menu-viewport-width)]",
				className
			)}
			{...props}
		/>
	</div>
);
NavigationMenuViewport.displayName = NavigationMenuPrimitive.Viewport.displayName;

const NavigationMenuIndicator = ({
	ref,
	className,
	...props
}: React.ComponentProps<typeof NavigationMenuPrimitive.Indicator>) => (
	<NavigationMenuPrimitive.Indicator
		className={cn(
			"top-full z-[1] flex h-1.5 items-end justify-center overflow-hidden data-[state=visible]:animate-in data-[state=hidden]:animate-out data-[state=hidden]:fade-out data-[state=visible]:fade-in",
			className
		)}
		{...props}
	>
		<div className="relative top-[60%] h-2 w-2 rotate-45 rounded-tl-sm border bg-white shadow-md" />
	</NavigationMenuPrimitive.Indicator>
);
NavigationMenuIndicator.displayName = NavigationMenuPrimitive.Indicator.displayName;

export {
	navigationMenuTriggerStyle,
	NavigationMenu,
	NavigationMenuList,
	NavigationMenuItem,
	NavigationMenuContent,
	NavigationMenuTrigger,
	NavigationMenuLink,
	NavigationMenuIndicator,
	NavigationMenuViewport,
};
