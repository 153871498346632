"use client";

import { getCookie } from "cookies-next";
import { XIcon } from "lucide-react";
import Link from "next/link";
import { useParams, useSearchParams } from "next/navigation";
import { useState } from "react";
import { Remarkable } from "remarkable";
import useSWR from "swr";
import useSWRImmutable from "swr/immutable";
import { AffiliateSettings, Hellobar } from "types";

import CountDownTimer from "@/components/countdown-timer";
import { FreeTrialWrapper } from "@/components/free-trial-wrapper";
import parse from "@/components/html-parser";
import { SalesHellobar } from "@/components/sales-hellobar";
import { Button } from "@/components/ui/button";
import { toast } from "@/components/ui/use-toast";
import { useUser } from "@/lib/hooks/auth";
import { fetchWrapper, swrFetcher } from "@/lib/hooks/fetch-client";
import { useEarlybird } from "@/lib/hooks/use-earlybird";
import { useSettings } from "@/lib/hooks/use-settings";
import { useSubscription } from "@/lib/hooks/use-subscription";
import { cn } from "@/lib/utils";

const defaultColor = "#FFD747";
const earlybirdEndDate = new Date("2024-12-02T12:00:00-04:00");

const useHellobar = (): Hellobar => {
	const token = getCookie("token");
	const affiliateCode = JSON.parse((getCookie("affiliate") as string) ?? "{}").code;

	const { data: userSettings } = useSWR(token ? ["/v1/members/settings", token] : null, swrFetcher);
	const { subscription } = useSubscription();
	const { partner } = useParams<{ partner: string }>();
	let { settings } = useSettings();
	const searchParams = useSearchParams();
	const { data: affiliateSettings } = useSWR<AffiliateSettings>(
		[`/v1/affiliate/settings?code=${affiliateCode}`, token],
		swrFetcher
	);
	const { data: hellobarSnippet } = useSWRImmutable(
		["/v1/page-snippets/hellobar-verify-email"],
		swrFetcher
	);
	const { user, isLoading } = useUser();

	settings = { ...settings, sale: true };

	const isPartnerPage = Boolean(partner);
	const isSales = settings?.sale;
	const isEarlybird = useEarlybird();

	let hellobar: Hellobar = {
		color: userSettings?.hello_bar?.color || defaultColor,
		content: userSettings?.hello_bar?.length === 0 ? null : userSettings?.hello_bar,
	};

	const [isSending, setIsSending] = useState(false);
	const handleSendVerification = async () => {
		setIsSending(true);
		try {
			const response = await fetchWrapper<{ message?: string }>(
				"/email/verification-notification",
				{
					method: "POST",
				}
			);

			if (response?.message) {
				toast({
					title: response.message,
					variant: "success",
				});
			}
		} catch (error) {
			toast({ title: "Error sending verification email", variant: "error" });
		} finally {
			setIsSending(false);
		}
	};

	const success = searchParams?.get("success");
	const error = searchParams?.get("error");
	const label = success || error;

	if (label) {
		return {
			color: success ? "#D1E7DD" : "#F8D7DA",
			content: label,
		};
	}

	if (!isLoading && user?.email_verified === 0 && !subscription?.is_active && hellobarSnippet) {
		return {
			color: defaultColor,
			content: (
				<div className="flex items-center gap-2">
					<span>{parse(hellobarSnippet.content)}</span>
					<Button
						variant="link"
						rounded="full"
						disabled={isSending}
						onClick={handleSendVerification}
						className="px-0 font-medium text-primary"
					>
						{isSending ? "Sending..." : "Send Verification Email"}
					</Button>
				</div>
			),
		};
	}

	if (affiliateSettings?.hello_bar && !Array.isArray(affiliateSettings?.hello_bar)) {
		hellobar = {
			color: affiliateSettings.hello_bar?.color,
			content: (
				<>
					{partner?.toUpperCase()} Exclusive: Save on Examine+ now
					<FreeTrialWrapper> and try it for free for {settings?.trial} days</FreeTrialWrapper>!
				</>
			),
			redirectTo: "#sales-pricing",
			showCounter: false,
		};
	}

	if (isPartnerPage) {
		hellobar = {
			...hellobar,
			content: (
				<strong>
					{partner?.toUpperCase()} Exclusive: Save on Examine+ now
					<FreeTrialWrapper> and try it for free for {settings?.trial} days</FreeTrialWrapper>!
				</strong>
			),
		};
	}

	if (isEarlybird) {
		hellobar = {
			...hellobar,
			content: <strong>Your Early Bird Exclusive Savings END TODAY:</strong>,
			redirectTo: "#sales-pricing",
			showCounter: true,
			saleEndDate: earlybirdEndDate,
		};
	}

	if (isSales) {
		hellobar = {
			...hellobar,
			content: <SalesHellobar />,
			asChild: true,
		};
	}

	if (!subscription?.is_active && subscription?.hello_bar) {
		hellobar = {
			color: defaultColor,
			content: subscription?.hello_bar?.length === 0 ? null : subscription?.hello_bar,
			redirectTo: subscription?.hello_bar?.includes("login") ? "/login/" : null,
		};
	}

	return hellobar;
};

const HellobarComponent = () => {
	const { content, asChild, color, redirectTo, saleEndDate, showCounter, className } =
		useHellobar();
	const [isClosed, setIsClosed] = useState<boolean>(false);
	const md = new Remarkable({ html: true });

	const handleClose = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
		e.preventDefault();

		setIsClosed(true);
	};

	if (!content || isClosed) {
		return null;
	}

	if (asChild) {
		return content;
	}

	const hellobarContent = (
		<div
			style={{ backgroundColor: color }}
			className={cn("group relative left-0 top-0 flex w-full justify-center p-2", className)}
		>
			<XIcon onClick={handleClose} className="absolute right-2 cursor-pointer" size={22} />
			<div>
				{content?.markdown ? (
					<p dangerouslySetInnerHTML={{ __html: md.render(content?.markdown) }} />
				) : (
					content
				)}
			</div>
			{showCounter && saleEndDate ? <CountDownTimer expiryTimestamp={saleEndDate} /> : null}
		</div>
	);

	return redirectTo ? <Link href={redirectTo}>{hellobarContent}</Link> : hellobarContent;
};

export { HellobarComponent };
